import React from "react"
import Hero from "./Hero"
import logo from "../images/spot-reporters-logo.svg"
import screenshot from "../images/mobile-reporters-hdr.png"
import useWindowSize from "../lib/useWindowSize"

function ReportersHero() {
  const size = useWindowSize()

  return (
    <Hero>
      <div className="hero-copy">
        <img src={logo} alt="Spot Mobile" />
        <p>Enable the public to report issues anytime, anywhere.</p>
      </div>
      <div
        className="hero-image"
        style={{ paddingTop: size.width >= 750 ? 32 : 0 }}
      >
        <img src={screenshot} alt="Spot Reporters" />
      </div>
    </Hero>
  )
}

export default ReportersHero
