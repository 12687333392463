import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import SideBySide from "../components/SideBySide"
import Ready from "../components/Ready"
import Quote from "../components/Quote"
import Headline from "../components/Headline"
import ReportersHero from "../components/ReportersHero"

function ReportersPage() {
  const data = useStaticQuery(graphql`
    query ReportersQuery {
      allReporterHightlightsYaml {
        nodes {
          id
          last
          title
          description
          direction
          imageSource {
            publicURL
          }
        }
      }
    }
  `)

  const title = "Spot Reporters"
  const headline = "What could a team of mobile reporters do for you?"
  const subheadline =
    "By making your organization’s services available to public mobile and Web users, you empower them to help make their community a better place."

  const quote = {
    quote: "Calling feels like complaining, reporting feels like helping.",
    citation: "Heather S., Boston",
  }

  const highlights = data.allReporterHightlightsYaml.nodes

  return (
    <Layout>
      <SEO title={title} />
      <ReportersHero />
      <Headline title={headline} summary={subheadline} />
      {highlights.map(highlight => (
        <SideBySide
          key={highlight.id}
          title={highlight.title}
          description={highlight.description}
          imageSource={highlight.imageSource.publicURL}
          direction={highlight.direction}
          last={highlight.last}
        />
      ))}
      <Quote citation={quote.citation}>{quote.quote}</Quote>
      <Ready
        file="/spot-mobile.pdf"
        title="Download Spot Mobile Overview (PDF)"
      />
    </Layout>
  )
}

export default ReportersPage
