import React from "react"

function Headline({ title, summary, stat }) {
  return (
    <div className={["container", "headline"].join(" ")}>
      <div className="row">
        <div
          className={stat ? "nine columns" : ""}
          style={{ textAlign: stat ? "left" : "center" }}
        >
          <h2>{title}</h2>
          <p>{summary}</p>
        </div>
        {stat && (
          <div className="three columns">
            <div className="data">{stat}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Headline
